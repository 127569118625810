import { exportTypes } from './ExportTypes';

export const strings = {
  export: 'Export',
  previousPeriod: 'Previous period',
  currentPeriod: 'Current period',
  yAxisLabel: currencySymbol => `Value(${currencySymbol})`,
  comparePeriod: 'Compare period',
  currentPeriodOnly: 'Current Period Only',
  legendMl: 'Machine Learning',
  legendManual: 'Manual',
  legendRules: 'Rules',
  legendUncategorised: 'Uncategorised',
  noDataMessagePt1: 'There is no data to display as nothing has yet been categorised. To begin, navigate to ',
  noDataMessagePt2: 'Categorisation',
  chartTitle: 'Distribution of value by category',
  exportListNote: 'Please select from the list below the type of file you would like to export.',
  exportListFormatNote: 'Note: If there are less than 1 million line items your file will be exported in XLSX format. Any larger files will be exported in CSV format.',
  availableFiles: 'Available file(s) for export',
  exportButton: 'Export selected file(s)',
  exportCombinedButton: 'Export consolidated report',
  exportSelectionValidation: 'You must select at least one report to export.',
  exporting: 'Exporting',
  exportProcessingMessagePart1: 'Your export is processing',
  exportProcessingMessagePart2: 'To make the export work smoothly, the system needs to do some ground work. Thus this period is currently in ‘read only’ mode.',
  exportProcessingMessagePart3: 'You can navigate to other periods which will be fully active.',
  exportProcessingMessagePart4: 'Thank you for your patience.',
  exportCompleteNotificationBody: 'The export process has finished running.',
  archiveCompleteNotificationBody: period => `Period ‘${period}’ has been archived successfully.`,
  clickHereToView: 'Click here to view.',
  noExportFilesMessage: 'Currently there are no exported files',
  externalReviewExportedFileExpiredMessage: 'The exported file is not available as it is expired. If you wish to export again, Please cancel external review and try again to export.',
  exportedFiles: 'Exported file(s)',
  expiresIn: 'Expires in',
  exportDeleteNote: 'Removing this file will permanently delete it from the tool.',
  exportDeleteTitle: 'Delete Export',
  dateCreated: 'Date created',
  customReportErrorTitle: 'No Files added',
  customReportErrorDescription: 'Please upload a file to create a Custom Report',
  consolidatedErrorTitle: 'Consolidated Report Error',
  consolidatedErrorDescription: 'Action Log and Custom Report are not allowed in Consolidated Report',
  consolidatedFileSizeErrorDescription: 'Export data count exceeds the Excel limit',
  exportReports: 'Export reports',
  tagsHeader: 'Tags',
  filterPivotTable: '',
  filterSelectPlaceholder: 'Filter',
  searchPlaceholder: 'Search',
  searchFilter: 'FilterSearch',
  exportPivotTableHeaders: ['Filter by', 'Columns', 'Rows', 'Values', 'Function'],
  exportPivotTableTitle: 'Pivot table',
  exportPivotTableDescription: 'Create a pivot table by dragging the relevant items to the corresponding boxes below. Generated pivot table reports can be found here - ',
  exportPivotTable: 'Create Pivot Table Report',
  exportPivotTableButton: 'Generate Pivot Table File',
  exportPivotTableResetFilterButton: 'Reset filter',
  exportPivotTableAddRowButton: 'Add subdimension',
  exportPivotTableRemoveRowButton: 'Remove last subdimension',
  exportPivotTableNoFilter: 'No pivot table filter applied',
  exportUncategorisedData: 'Export uncategorised data',
  exportUncategorisedDataStep1: 'Step 1 of 2: Export uncategorised data',
  exportUncategorisedDataStep2: 'Step 2 of 2: Import a completed uncategorised data sheet',
  exportUncategorisedDataProcessDataTitle: 'Import a completed uncategorised data sheet',
  exportUncategorisedDescription: 'You have data items remaining that is uncategorised that can be exported for external review. ' +
    'You can export these items into an excel sheet where data can be categorise manually. When you choose to export this data, ' +
    'the period will be locked until the completed excel sheet has been uploaded. You will be given the option to cancel.',
  exportUncategorisedDataProcessDataDescription: 'The completed uncategorised data sheet has been uploaded. When you choose to ‘Process data’ the file will be processed and you will be redirected to the review screen.',
  processDataButton: 'Process data',
  exportModalDescription: 'Are you sure you want to export uncategorised data? The period will be locked once exported.',
  cancelExternalReview: 'Cancel external review',
  cancelExternalReviewModalDescription: 'Are you sure you want to cancel this external review? In doing so the period will be unlocked and no data that has been external reviewed can be imported.',
  exportUncategorisedDataStep2Description: 'When the uncategorised data sheet has been completed manually, you can re-upload it into the tool here. It can then be processed and you will be redirected ' +
    'to the review screen. You can cancel the external review using the link to the right. This will unlock the period.  ',
  exportUncategorisedDataStep1Description: uncategorisedLineItemCount => `The ${uncategorisedLineItemCount} uncategorised line items in this period can be exported to Excel for manual analysis by someone without access to TDAE. Once you have exported the data, the period will be locked until the completed Excel sheet has been uploaded. Should you need to unlock the period before this can be done, please contact your administrator.`,
  apportionmentDefinitionNotCompleted: 'Please be aware that you have line items in the apportionable category that have not yet been calculated. This may be because you have no conditions, invalid conditions defined or the calculate button has not been clicked since conditions were last applied. Please navigate to apportionables category screen and click calculate to update all categorisations before exporting.',
};

export const exportProcessingArray =
  [
    strings.exportProcessingMessagePart1,
    strings.exportProcessingMessagePart2,
    strings.exportProcessingMessagePart3,
    strings.exportProcessingMessagePart4,
  ];

export const exportList = [
  {
    name: 'Categorised Data',
    exportType: exportTypes.categorisedData,
    disabled: false,
  },
  {
    name: 'Categorised Data (with supporting documents detail)',
    exportType: exportTypes.categorisedDataWithSupportingDocuments,
    disabled: false,
  },
  {
    name: 'Action Log',
    exportType: exportTypes.auditLog,
    disabled: false,
  },
  {
    name: 'Rule Report',
    exportType: exportTypes.ruleData,
    disabled: false,
  },
  {
    name: 'Categories Report',
    exportType: exportTypes.categoriesReport,
    disabled: false,
  },
  {
    name: 'Custom Report',
    exportType: exportTypes.customReport,
    disabled: false,
  },
  {
    name: 'Dashboard Summary',
    exportType: exportTypes.dashboardSummary,
    disabled: false,
  },
  {
    name: 'Tag Details Summary',
    exportType: exportTypes.tagDetailsSummary,
    disabled: false,
  },
];

export const exportFilesColumns = [
  strings.exportedFiles,
  strings.dateCreated,
  strings.expiresIn,
];

export const exportPageType = {
  exportReports: 0,
  exportExternalReview: 1,
};
