import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import ChangePeriodStatus from 'Components/Projects/ChangePeriodStatus';
import { enableDemoGroup } from 'Store/Areas/Setup/Admin/GroupActions';
import plusIcon from '../../../Assets/Images/plus-ic.svg';
import chevronIcon from '../../../Assets/Images/chevron-down-ic.svg';
import styles from './TreePickerNode.styles';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

/*
  To Create a tree picker nest tree picker nodes within each other e.g.
  <TreePickerNode ...props>
    <TreePickerNode ...props>
      <div>This is the last level</div>
    </TreePickerNode>
  </TreePickerNode>
*/
class TreePickerNode extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
      isOpen: false,
    };
  }

  onMouseEnter = () => {
    this.setState({
      isHovering: true,
    });
  }

  onMouseExit = () => {
    if (!this.state.isOpen) {
      this.setState({
        isHovering: false,
      });
    }
  }

  onHoverClick(event) {
    this.props.onHoverClick();
    event.stopPropagation();
  }

  openModal = () => {
    this.setState({ isOpen: true });
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  }

  onNodeClick = () => {
    if (!this.state.isOpen) {
      this.props.onNodeClick(this.props.index);
    }
  }

  getIndentationClass() {
    const { classes } = this.props;
    switch (this.props.nodeLevel) {
      case 0: return classes.level0;
      case 1: return classes.level1;
      case 2: return classes.level2;
      case 3: return classes.level3;
      default: return '';
    }
  }

  onClickChangeStatus = (event) => {
    event.stopPropagation();
    this.openModal();
  }

  onClickDemo = (prop) => {
    const { dispatch } = this.props;
    dispatch(enableDemoGroup(prop.group.id, prop.enableDemoText));
  }

  render() {
    const {
      title,
      nodeLevel,
      expanded,
      add,
      selected,
      classes,
      hoverText,
      enableChangeStatusText,
      enableDemoText,
      hideBorder,
      period,
    } = this.props;

    const { isHovering } = this.state;

    const classNames = `
      ${classes.flexContainer}
      ${this.getIndentationClass(nodeLevel)}
      ${classes.node}
      ${selected ? classes.selected : ''}
      ${nodeLevel < 3 ? classes.paddingLarge : classes.paddingSmall}
      ${nodeLevel < 3 ? classes.text : classes.smallText}
      ${hideBorder ? classes.hideBorder : ''}`;

    const paddingClassNames = `${classes.level3Padding} ${selected || isHovering ? classes.selected : ''}`;

    return (
      <React.Fragment>
        <div className={nodeLevel === 3 ? classes.container : ''}>
          <If condition={nodeLevel === 3} >
            <div className={paddingClassNames} />
          </If>
          <div
            className={classNames}
            onClick={this.onNodeClick}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseExit}
            role="presentation"
          >
            <span className={`${classes.flex} ${add && classes.add}`}>
              {title}
            </span>
            <If condition={enableChangeStatusText && (isHovering || selected)} >
              <span
                className={classes.hoverText}
                onClick={this.onClickChangeStatus}
              >
                {enableChangeStatusText}
              </span>
              <div>
                <ChangePeriodStatus
                  isOpen={this.state.isOpen}
                  onClose={this.closeModal}
                  period={period}
                />
              </div>
            </If>
            <If condition={enableDemoText && (isHovering || selected)} >
              <span
                className={classes.hoverText}
                onClick={() => this.onClickDemo(this.props)}
              >
                {enableDemoText}
              </span>
            </If>
            <If condition={hoverText && (isHovering || selected)} >
              <span className={classes.hoverText} onClick={e => this.onHoverClick(e)}>
                {hoverText}
              </span>
            </If>
            {add &&
              <span className={classes.iconContainer} >
                <img alt="Add" src={plusIcon} />
              </span>
            }
            {this.props.children &&
              <span className={classes.iconContainer}>
                <img
                  alt={`${expanded ? 'Collapse menu item.' : 'Expand menu item.'}`}
                  src={chevronIcon}
                  className={`${!expanded ? classes.rotateAntiClockwise : ''}`}
                />
              </span>}
          </div>
          {expanded &&
            <div>
              {this.props.children}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

TreePickerNode.defaultProps = {
  index: -1,
  children: '',
  title: '',
  expanded: false,
  hoverText: '',
  enableChangeStatusText: '',
  enableDemoText: '',
  nodeLevel: 0,
  onHoverClick: () => { },
  onNodeClick: () => { },
  add: false,
  selected: false,
  hideBorder: false,
  period: {
    isLocked: false,
    status: '',
  },
};

TreePickerNode.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dispatch: PropTypes.func.isRequired,
  expanded: PropTypes.bool, // True = Display node children
  hoverText: PropTypes.string, // Text to show on hover
  enableChangeStatusText: PropTypes.string,
  enableDemoText: PropTypes.string,
  nodeLevel: PropTypes.oneOf([0, 1, 2, 3]), // Sets indentation
  onHoverClick: PropTypes.func, // Callback function for hover text click
  onNodeClick: PropTypes.func, // Callback function for node
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]), // Node display title
  add: PropTypes.bool, // True = display plus icon.
  selected: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hideBorder: PropTypes.bool,
  index: PropTypes.number,
  period: PropTypes.shape({
    data: PropTypes.shape({
      isLocked: PropTypes.bool,
      status: PropTypes.string,
    }),
  }),
};

export default compose(
  withStyles(styles),
  withTheme(),
)(TreePickerNode);
