/* Projects Endpoints */
export const PROJECTS_GET_TREEPICKER_URL = 'api/Group/GetGroups';
export const PROJECTS_GET_TEMPLATES_URL = (contextId, groupId) => `api/Template/GetTemplates/${contextId}/${groupId}`;
export const PROJECTS_POST_PERIOD_URL = 'api/Period/AddPeriod';
export const PROJECTS_EDIT_PERIOD_URL = 'api/Period/EditPeriod';

/* Period Endpoints */
export const PERIOD_SET_IN_USE = 'api/Period/SetPeriodInUse';
export const PERIOD_RESET_IN_USE = 'api/Period/ResetPeriodInUse';
export const PERIODS_GET_PERIOD = periodId => `api/Period/${periodId}`;
export const PERIODS_GET_PERIOD_INFO = periodId => `api/Period/GetPeriodInfo/${periodId}`;
export const PERIOD_POST_ADD_CATEGORY = 'api/Category/AddCategory';
export const PERIOD_CHECK_CATEGORY_CAN_BE_DELETED = (categoryId, periodId) => `api/Category/CheckCategoryCanBeDeleted/${categoryId}/${periodId}`;
export const PERIOD_POST_DELETE_CATEGORY = 'api/Category/DetachCategory';
export const PERIOD_GET_KEYWORDS = 'api/Period/GetKeywords/';
export const PERIOD_GET_SEARCH_KEYWORDS = 'api/Period/GetSearchKeywords';
export const PERIOD_GET_SEARCH_WHOLE_CELL_VALUES = 'api/Period/GetSearchWholeCellValues';
export const PERIOD_GET_REFERENCE_LIST_VALUES = 'api/Period/GetReferenceListValues';
export const PERIOD_GET_REFERENCE_LISTS = periodId => `api/Period/GetReferenceLists/${periodId}`;
export const PERIOD_GET_REFERENCE_LIST_ENTRIES = 'api/Period/GetReferenceListEntries';
export const PERIOD_UPLOAD_AND_REPLACE_REFERENCE_LIST = 'api/Period/UploadAndReplaceReferenceList';
export const PERIOD_GET_REFERENCE_LIST_RULES = 'api/Period/GetReferenceListRules';
export const PERIOD_POST_DELETE_REFERENCE_LIST = 'api/Period/DeleteReferenceList';
export const PERIOD_POST_EDIT_CATEGORY = 'api/Category/UpdateCategory';
export const PERIOD_UPDATE_STATUS = 'api/Period/SetStatus';
export const PERIOD_DELETE_PERIOD = 'api/Period/DeletePeriod';
export const PERIOD_CLEAR_DATA = 'api/Period/ClearPeriodData';
export const PERIOD_CLEAR_FILES = 'api/FileUpload/ClearPeriodFiles';
export const PERIOD_ARCHIVE_PERIOD = 'api/Period/ArchivePeriod';
export const TOGGLE_PERIOD_LOCK_URL = (adminOnly, lock) => `api/Period/TogglePeriodLock/${adminOnly ? 'Admin' : 'User'}/${lock ? 'Lock' : 'Unlock'}`;
export const PERIOD_GET_PERIOD_LOCKED_BY_USERID = periodId => `api/Period/GetPeriodLockedByUserId/${periodId}`;
export const PERIOD_GET_PERIOD_LOCKED_BY_USER = periodId => `api/Period/GetPeriodLockedByUser/${periodId}`;

/* Rule Endpoints */
export const RULES_CREATE_RULES = 'api/Rule/CreateRules';
export const RULES_CREATE_RULE = 'api/Rule/CreateRule';
export const RULES_EDIT_RULE = 'api/Rule/EditRule';
export const RULE_GET_NEXT_CONFLICT = periodId => `api/Rule/${periodId}/GetNextConflict`;
export const RULE_PUT_RESOLVE_CONFLICT = 'api/Rule/ResolveRuleConflict';
export const RULE_PUT_ALLOW_CONFLICT = 'api/Rule/AllowRuleConflict';
export const RULE_PUT_ALLOW_ALL_CONFLICT = 'api/Rule/AllowAllRuleConflict';
export const RULE_GET_RULES = 'api/Rule/GetCategoryRules';
export const RULE_REQUEST_DELETE_RULE = 'api/Rule/DeleteRule';
export const RULE_CURRENT_PRIORITY = 'api/Rule/GetRulePriority';
export const RULE_EDIT_PRIORITY = 'api/Rule/EditRulePriority';
export const RULE_REQUEST_EDIT_RULE_NOTE = 'api/Rule/EditRuleNote';
export const RULE_GET_IMPORT_FAILED_RULES = 'api/Rule/GetImportFailedRules';

/* File Upload Endpoints */
export const UPLOAD_POST_FILE_URL = 'api/FileUpload/UploadPeriodFile';
export const PROCESS_PERIOD_URL = periodId => `api/Import/StartProcessing/${periodId}`;
export const RESTART_PROCESS_PERIOD_URL = periodId => `api/Import/RestartProcessing/${periodId}`;
export const PROCESS_NEW_SUPPORTING_DOCS_URL = 'api/Import/ProcessNewSupportingDocs';
export const RETRY_FAILED_IMPORT_URL = 'api/Import/RetryImport/';
export const RESUME_PROCESS_PERIOD_URL = 'api/Import/ResumeProcessing/';
export const QUEUE_MACHINE_LEARNING_PREDICTIONS_URL = 'api/Import/QueueMachineLearningPredictions/';
export const CANCEL_IMPORT_URL = 'api/Import/CancelImportPeriod/';
export const DELETE_FILE_URL = 'api/FileUpload/DeletePeriodFile';
export const MAP_FILE_TO_SCHEMA_URL = 'api/Import/MapFileToSchema';
export const UPLOAD_POST_CUSTOM_REPORT_URL = 'api/FileUpload/UploadCustomReport';
export const UPLOAD_POST_REFERENCE_LIST_URL = 'api/FileUpload/UploadReferenceList';
export const UPLOAD_EXTERNAL_REVIEW_FILE_URL = 'api/FileUpload/UploadExternalReviewFile';

/* Setup Endpoints */
export const ADMIN_GET_USERS_URL = 'api/Admin/GetUsers';
export const ADMIN_POST_USER_URL = 'api/Admin/AddUser';
export const ADMIN_POST_GROUP_URL = 'api/Group/SaveGroup';
export const ADMIN_POST_DEMO_GROUP_URL = 'api/Group/DemoGroup';
export const ADMIN_GET_GROUP_URL = groupId => `api/Group/${groupId}`;
export const ADMIN_POST_PROJECT_URL = 'api/Project/AddProject';
export const ADMIN_GET_PROJECT_URL = projectId => `api/Project/${projectId}`;
export const ADMIN_POST_ENTITY_URL = 'api/Entity/SaveEntity';
export const ADMIN_GET_ENTITY_URL = entityId => `api/Entity/${entityId}`;
export const ADMIN_GET_CURRENCIES_URL = 'api/Currency/GetCurrencies';
export const ADMIN_GET_TAX_CONTEXTS_URL = 'api/TaxContext/GetTaxContexts';
export const ADMIN_GET_GROUPS_URL = 'api/Admin/GetAdminGroups';
export const ADMIN_POST_TOGGLE_ACCESS_URL = 'api/Admin/ToggleUserAccess';
export const ADMIN_POST_REMOVE_ACCESS_URL = 'api/Admin/RemoveUserAccess';
export const ADMIN_DELETE_ENTITY = 'api/Entity/DeleteEntity';
export const ADMIN_DELETE_PROJECT = 'api/Project/DeleteProject';
export const ADMIN_DELETE_GROUP = 'api/Group/DeleteGroup';

/* User Endpoints */
export const USER_GET_PERMISSIONS_URL = 'api/User/GetPermissions';
export const USER_GET_CLIENT_CONFIGURATION = 'api/User/GetClientConfiguration';
export const USER_STORE_NEW_USER_SESSION_URL = 'api/User/StoreNewUserSession';
export const USER_VALIDATE_USER_SESSION = 'api/User/ValidateUserSession';
export const USER_INVALIDATE_USER_SESSION = 'api/User/InvalidateUserSession';
export const USER_DOWNLOAD_USER_GUIDE = 'api/User/DownloadUserGuide';

/* Review Category Endpoints */
export const CATEGORY_GET_POPULAR_KEYWORDS = (categoryId, periodId) => `api/Category/GetPopularKeywords/${categoryId}/${periodId}`;
export const CATEGORY_GET_POPULAR_KEYWORDS_BY_TAG = 'api/Category/GetPopularKeywordsByTag';
export const REVIEW_GET_METHOD_OF_CATEGORISATION = periodId => `api/LineItem/GetCategorisationByType/${periodId}`;

/* Export endpoints */
export const EXPORT_REQUEST_EXPORT = 'api/Export/Export';
export const EXPORT_GET_EXPORT_FILES = (periodId, isExternalReview) => `api/Export/GetPeriodExportFiles/${periodId}/${isExternalReview}`;
export const EXPORT_REQUEST_DOWNLOAD = 'api/Export/DownloadExportFile';
export const DELETE_EXPORT_REQUEST = 'api/Export/DeleteExportFile';
export const CLEAR_EXPORT_REQUEST = 'api/Export/ClearExportFiles';
export const DELETE_EXTERNAL_REVIEW_EXPORT_REQUEST = 'api/Export/DeleteExternalReviewExportFile';
export const EXPORT_GET_EXTERNAL_REVIEW_IMPORTED_FILE = periodId => `api/Export/GetPeriodExternalReviewFile/${periodId}`;
export const EXPORT_GET_UNCATEGORISED_LINE_ITEMS_COUNT = periodId => `api/Export/GetNumberOfUncategorisedDataLineItems/${periodId}`;
export const IS_EXPORTED_FILE_EXPIRED = periodId => `api/Export/IsExternalReviewExportedFileExpired/${periodId}`;
export const EXPORT_GET_PIVOT_TABLE_FILTER_VALUES = 'api/Export/GetPivotTableFilterValues';

/* Import endpoints */
export const EXPORT_PROCESS_EXTERNAL_REVIEW_IMPORTED_FILE = 'api/Import/ImportExternalReviewFile';

/* Mapping endpoints */
export const MAPPING_GET_PREVIEW_ROWS = (periodId, fileType) => `api/Import/GetPreviewRows/${periodId}/${fileType}`;

/* Import Summary endpoints */
export const SUMMARY_GET_IMPORT_SUMMARY = periodId => `api/Import/GetImportSummary/${periodId}`;
export const REMOVE_FILE_IMPORT_ROWS = 'api/Import/RemoveImportErrors';
export const IMPORT_IDENTIFY_CONTRAS = 'api/Import/IdentifyContras';
export const PERIOD_IMPORT_DETAILS = periodId => `api/Import/GetPeriodImportDetails/${periodId}`;

/* Tag endpoints */
export const TAG_ADD_TAG = 'api/Tag/AddTag';
export const TAG_DELETE_TAG = 'api/Tag/DeleteTag';
export const TAG_MAP_TAG = 'api/Tag/MapTag';
export const TAG_AUTO_MAP_SUPPORTING_HEADERS = 'api/Tag/AutoMapSupportingHeaders';

/* Line Item endpoints */
export const LINE_ITEM_GET_LINE_ITEMS = 'api/LineItem/GetLineItems';
export const LINE_ITEM_CATEGORISE_LINE_ITEMS = 'api/LineItem/CategoriseLineitems';
export const LINE_ITEM_UNCATEGORISE_LINE_ITEM = 'api/LineItem/UncategoriseLineItems';
export const LINE_ITEM_GET_HIGHEST_VALUE_LINE_ITEM = periodId => `api/LineItem/GetAbsoluteMaxValue/${periodId}`;
export const LINE_ITEM_GET_LINE_ITEM_METADATA = 'api/LineItem/GetLineItemMetadata';
export const LINE_ITEM_MARK_LINE_ITEMS_AS_CONTRA = 'api/LineItem/MarkLineItemsAsContra';
export const LINE_ITEM_MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED = 'api/LineItem/MarkLineItemsAsFlaggedOrUnflagged';

/* Machine learning endpoints */
export const ML_GET_ALL_VERSIONS = 'api/MachineLearning/GetAllVersions';
export const ML_GET_MODELS_LATEST_VERSION = projectId => `api/MachineLearning/GetModelsOfLatestVersion/${projectId}`;
export const ML_GET_MAX_LINE_ITEM_THRESHOLD = periodId => `api/MachineLearning/GetMaxLineItemThreshold/${periodId}`;
export const ML_ACTIVATE_VERSION = 'api/MachineLearning/ActivateVersion';
export const ML_ADD_MODEL = 'api/MachineLearning/CreateModel';
export const ML_EDIT_MODEL = 'api/MachineLearning/EditModel';
export const ML_DELETE_MODEL = 'api/MachineLearning/DeleteModel';
export const ML_CHECK_MODEL_USED = 'api/MachineLearning/CheckModelUsed';
export const ML_APPLY_MACHINE_LEARNING_URL = 'api/MachineLearning/ApplyMachineLearning';
export const ML_SKIP_MACHINE_LEARNING_URL = 'api/MachineLearning/SkipMachineLearning';
export const ML_GET_GRAPH_DATA = 'api/MachineLearning/GetDistributionGraphData';
export const ML_GET_CATEGORISATION_REASON = 'api/MachineLearning/GetCategorisationReason';
export const ML_COPY_MODELS = 'api/MachineLearning/CopyModelsToCurrentVersion';

/* Template endpoints */
export const ADMIN_UPLOAD_TEMPLATE = 'api/FileUpload/UploadTemplate';
export const ADMIN_GET_TEMPLATES = 'api/Admin/GetTemplates';
export const ADMIN_DELETE_TEMPLATES = 'api/Admin/DeleteTemplate';
export const ADMIN_DOWNLOAD_TEMPLATE_URL = templateId => `api/Admin/DownloadTemplate/${templateId}`;
export const GET_TEMPLATE_RULES_WHILE_CREATE_PERIOD = templateId => `api/Template/GetTemplateRules/${templateId}`;

/* Bulk Upload endpoints */
export const ADMIN_BULK_USER_UPLOAD = 'api/FileUpload/BulkUserUpload';
export const ADMIN_BULK_GROUP_ENTITY_PROJECT_UPLOAD = 'api/FileUpload/BulkGroupEntityProjectUpload';

/* Contra Endpoints */
export const CONTRA_GET_REVIEW_CONTRAS = 'api/Contra/GetContras';
export const CONTRA_POST_REMOVE_CONTRAS = 'api/Contra/RemoveContra';
export const CONTRA_POST_REMOVE_CONTRAS_RERUN_CATEGORISATION = 'api/Contra/RemoveContraAndRerunCategorisation';

/* Export Endpoints */
export const FILE_EXPORT_REQUEST = 'api/Export/RequestExport';

/* Supporting Docs Endpoints */
export const SUPPORTING_DOCS_PROCESS = 'api/SupportingDocuments/Process';
export const MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_URL = 'api/SupportingDocuments/GetMatchedSupportingAndMainDocDetails';
export const PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS_URL = 'api/SupportingDocuments/ProcessMatchedSupportingAndMainDocs';
export const DELETE_SUPPORTING_DOC_URL = 'api/SupportingDocuments/DeleteSupportingDoc';
export const UPDATE_NEW_SUPPORTING_DOCS_URL = 'api/SupportingDocuments/UpdateNewSupportingDocs';

/* Settings Endpoints */
export const GET_SETTINGS_URL = 'api/Setting/GetSettings';
export const UPDATE_SETTINGS_URL = 'api/Setting/UpdateSettings';
export const GET_SITE_MESSAGE_URL = 'api/Setting/GetSiteMessage';

/* Apportionment Endpoints */
export const CREATE_APPORTIONABLE_URL = 'api/Apportionment/CreateApportionable';
export const GET_LINE_ITEMS_APPORTIONABLES = 'api/Apportionment/GetLineItemForApportionables';
export const GET_APPORTIONABLE_LINE_ITEMS = 'api/Apportionment/GetApportionables';
export const UPDATE_APPORTIONMENT_DEFINITION_URL = 'api/Apportionment/UpdateApportionmentDefinition';
export const CALCULATE_APPORTIONMENT_URL = periodId => `api/Apportionment/CalculateApportionment/${periodId}`;
export const CHECK_FOR_APPORTIONMNET_CALCULATION_URL = periodId => `api/Apportionment/IsReadyForCalculation/${periodId}`;

/* User Login */
export const CHECK_EXISTING_EXTERNAL_USER_URL = emailAddress => `api/Admin/CheckExternalUserExists/${emailAddress}`;
export const ADD_EXTERNAL_USER_URL = 'api/Admin/MigrateExternalUser';
