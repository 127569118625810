import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  REQUEST_UPLOAD_PERIOD_FILE,
  REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
  REQUEST_UPLOAD_REFERENCE_LIST_FILE,
  REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
  requestUploadFileSuccess,
} from 'Store/Areas/FileUpload/FileUploadActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { setCustomReportLoading } from 'Store/Areas/Export/ExportActions';
import { setReferenceListFileLoading } from 'Store/Areas/Categorisation/ReferenceListActions';
import { setExternalReviewFileLoading } from 'Store/Areas/Export/ExternalReviewActions';
import { PostFile } from 'Store/Api/CallApi';
import { UPLOAD_POST_FILE_URL, UPLOAD_POST_CUSTOM_REPORT_URL, UPLOAD_POST_REFERENCE_LIST_URL, UPLOAD_EXTERNAL_REVIEW_FILE_URL } from 'Store/Api/ApiEndpoints';
import { responseStatusCodes } from 'Store/Api/StatusCodes';

/* Worker Sagas */
function* handleResponse(url, response, chunkResponse) {
  if (response.ok) {
    const data = chunkResponse != null ? chunkResponse : yield response.json();
    yield put(requestUploadFileSuccess(data));
  } else if (response.status === responseStatusCodes.badRequest) {
    switch (url) {
      case REQUEST_UPLOAD_CUSTOM_REPORT_FILE:
        yield put(setCustomReportLoading(false));
        break;
      case REQUEST_UPLOAD_REFERENCE_LIST_FILE:
        yield put(setReferenceListFileLoading(false));
        break;
      case REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE:
        yield put(setExternalReviewFileLoading(false));
        break;
      default:
    }
    const data = yield response.json();
    yield put(displayToastNotification(data.error));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* uploadFileInChunks(
  url,
  file,
  periodId,
  changeSupportingDocsAfterPeriodCreation,
  periodFileUploadChunkSize,
) {
  const chunkSize = periodFileUploadChunkSize; // 10MB
  const totalChunks = Math.ceil(file.size / chunkSize);
  let cloudReference = '';

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex += 1) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('periodId', periodId);
    formData.append('changeSupportingDocsAfterPeriodCreation', changeSupportingDocsAfterPeriodCreation);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);
    formData.append('cloudReference', cloudReference);

    const response = yield PostFile(url, formData);
    const responseData = yield response.json();
    if (response.ok) {
      const { file: { cloudReference: newCloudReference } } = responseData;
      cloudReference = newCloudReference;
      if (chunkIndex === totalChunks - 1) {
        yield handleResponse(url, response, responseData);
      }
    }
  }
}

function* postUploadFileForPeriod(url, action) {
  const {
    file, periodId, changeSupportingDocsAfterPeriodCreation,
    periodFileChunkSize, periodFileUploadChunkSize,
  } = action.payload;

  if (file.size > periodFileChunkSize) { // If file size is greater than 60MB
    yield uploadFileInChunks(
      url,
      file,
      periodId,
      changeSupportingDocsAfterPeriodCreation,
      periodFileUploadChunkSize,
    );
  } else {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('periodId', periodId);
    formData.append('changeSupportingDocsAfterPeriodCreation', changeSupportingDocsAfterPeriodCreation);

    const response = yield PostFile(url, formData);
    yield handleResponse(url, response, null);
  }
}

/* Watcher Sagas */
export function* fileUploadSagas() {
  yield takeLatest(REQUEST_UPLOAD_PERIOD_FILE, postUploadFileForPeriod, UPLOAD_POST_FILE_URL);

  yield takeLatest(
    REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
    postUploadFileForPeriod,
    UPLOAD_POST_CUSTOM_REPORT_URL,
  );
  yield takeLatest(
    REQUEST_UPLOAD_REFERENCE_LIST_FILE,
    postUploadFileForPeriod,
    UPLOAD_POST_REFERENCE_LIST_URL,
  );
  yield takeLatest(
    REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
    postUploadFileForPeriod,
    UPLOAD_EXTERNAL_REVIEW_FILE_URL,
  );
}
