import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Card,
  Grid,
  withStyles,
  withTheme,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { requestUploadPeriodFile, toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import TagInstructions from 'Components/Upload/Shared/TagInstructions';
import DropzoneContent from 'Components/Upload/Shared/DropzoneContent';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import UploadStatus from 'Components/Upload/Shared/UploadStatus';
import { acceptedSpreadsheetTypes, main, unmapped, supporting } from 'Constants/FileTypes';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { fileStatuses } from 'Constants/FileStatuses';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/Upload/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import {
  periodStatusSelector,
  changeSupportingDocsAfterPeriodCreationSelector,
  periodInUseSelector,
  periodLockedByUserIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { getSettingsData } from 'Store/Areas/Settings/SettingsActions';
import styles from './UploadPage.styles';
import FileList from './FileList/FileList';

class UploadPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInvalidFilesModal: false,
      userDismissedFileSchemaError: false,
      showNoFileTypeSelectedModal: false,
      showNoMainModal: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getSettingsData());
  }

  componentWillMount() {
    document.title = title.uploadPage;
  }

  onFileSelected(file) {
    const {
      dispatch, projects, period, changeSupportingDocsAfterPeriodCreation,
      periodFileChunkSize, periodFileUploadChunkSize,
    } = this.props;
    dispatch(requestUploadPeriodFile(
      file,
      projects.userTreePicker.selectedPeriodId,
      changeSupportingDocsAfterPeriodCreation,
      periodFileChunkSize,
      periodFileUploadChunkSize,
    ));
    if (period.data.status !== periodStatuses.notStarted) {
      dispatch(updatePeriodStatus(period.periodId, periodStatuses.notStarted));
    }
  }

  onProceedClick = () => {
    const { dispatch, period, changeSupportingDocsAfterPeriodCreation } = this.props;

    if (period.data.files.filter(x => x.fileType === unmapped).length > 0) {
      this.toggleNoFileTypeSelectedModal(true);
    } else if (!period.data.files.filter(x => x.fileType === main).length > 0) {
      this.toggleNoMainModal(true);
    } else if (period.data.files.every(x => x.fileStatus === fileStatuses.passedScan)) {
      if (changeSupportingDocsAfterPeriodCreation) {
        dispatch(updatePeriodStatus(period.periodId, periodStatuses.mapping));
        // trigger update of period status
        this.props.dispatch(push(routes.import.mappingSupporting));
      } else {
        dispatch(updatePeriodStatus(period.periodId, periodStatuses.mapping));
        // trigger update of period status
        this.props.dispatch(push(routes.import.mapping));
      }
    } else {
      this.toggleInvalidFilesModal(true);
    }
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({ showModal: true }));
  }

  toggleInvalidFilesModal = (visible) => {
    this.setState({
      showInvalidFilesModal: visible,
    });
  }
  toggleNoFileTypeSelectedModal = (visible) => {
    this.setState({
      showNoFileTypeSelectedModal: visible,
    });
  }
  toggleNoMainModal = (visible) => {
    this.setState({
      showNoMainModal: visible,
    });
  }

  dismissFileSchemaError = () => {
    this.setState({
      userDismissedFileSchemaError: true,
    });
  }
  render() {
    const {
      classes,
      currencySymbol,
      period,
      schemaMapping,
      changeSupportingDocsAfterPeriodCreation,
      periodInUse,
      periodLockedByUserId,
      permissions,
    } = this.props;
    const {
      showInvalidFilesModal,
      userDismissedFileSchemaError,
      showNoFileTypeSelectedModal,
      showNoMainModal,
    } = this.state;
    const fileCount = period.data ? period.data.files.length : 0;
    return (
      <Fragment>
        <UploadBreadcrumb index={constants.indexes.upload} />
        <Card className={`${classes.card} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabled : ''}`}>
          <Grid container direction="row" alignItems="stretch">
            <If condition={period.data}>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <TagInstructions />
              </Grid>
            </If>
            <Grid item xs={6} sm={6} md={9} lg={9}>
              <If condition={fileCount > 0}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <UploadStatus
                      onClick={this.onProceedClick}
                      buttonText={changeSupportingDocsAfterPeriodCreation ?
                        strings.proceedToLineItemMapping : strings.proceedToMapping
                      }
                      isButtonDisabled={changeSupportingDocsAfterPeriodCreation &&
                        !period.data.files
                          .find(x => x.fileType === supporting &&
                                x.amountValue === 0)
                      }
                    >
                      <div className={classes.didYouKnowContainer}>
                        <div className={classes.didYouKnowTitle}>{commonStrings.didYouKnow}</div>
                        <p className={classes.didYouKnow}>{strings.didYouKnowLine1}</p>
                        <p className={classes.didYouKnow}>{strings.didYouKnowLine2}</p>
                      </div>
                    </UploadStatus>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FileList
                    currencySymbol={currencySymbol}
                    period={period.data}
                    changeSupportingDocsAfterPeriodCreation={
                      changeSupportingDocsAfterPeriodCreation
                    }
                  />
                </Grid>
              </If>
              <Grid item xs={12} className={classes.uploadContent}>
                <FileUploader
                  className={classes.flex}
                  onFileSelected={files => this.onFileSelected(files[0])}
                  onDropRejected={this.onFileRejected}
                  fileTypes={acceptedSpreadsheetTypes}
                >
                  <DropzoneContent inReview={period.data.files.length !== 0} />
                </FileUploader>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <ErrorModal
          open={showInvalidFilesModal}
          onClose={() => this.toggleInvalidFilesModal(false)}
          title={strings.invalidFilesModalTitle}
          error={strings.invalidFilesModalDescription}
        />
        <ErrorModal
          open={showNoFileTypeSelectedModal}
          onClose={() => this.toggleNoFileTypeSelectedModal(false)}
          title={strings.noFileTypeSelectedModalTitle}
          error={strings.noFileTypeSelectedModalDescription}
        />
        <ErrorModal
          open={showNoMainModal}
          onClose={() => this.toggleNoMainModal(false)}
          title={strings.noMainModalTitle}
          error={strings.noMainModalDescription}
        />
        <ErrorModal
          open={schemaMapping.failed && !userDismissedFileSchemaError}
          onClose={this.dismissFileSchemaError}
          title={strings.schemaMappingErrorModalTitle}
          error={schemaMapping.fileType === main ?
            strings.schemaMappingErrorDescriptionMain :
            strings.schemaMappingErrorDescriptionSupporting}
        />
      </Fragment>
    );
  }
}

UploadPage.defaultProps = {
  periodLockedByUserId: -1,
};

UploadPage.propTypes = {
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    periodId: PropTypes.number,
    data: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })).isRequired,
      files: PropTypes.arrayOf(PropTypes.shape({
        fileType: PropTypes.string,
      })).isRequired,
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  schemaMapping: PropTypes.shape({
    failed: PropTypes.bool.isRequired,
    fileType: PropTypes.string,
  }).isRequired,
  fileUpload: PropTypes.shape({
    uploadResponse: PropTypes.object,
    checkingForViruses: PropTypes.bool.isRequired,
    showCorruptedFileModal: PropTypes.bool.isRequired,
    fileContainsVirus: PropTypes.bool.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool.isRequired,
  periodInUse: PropTypes.bool.isRequired,
  periodLockedByUserId: PropTypes.number,
  permissions: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  periodFileChunkSize: PropTypes.number.isRequired,
  periodFileUploadChunkSize: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const periodFileChunkSizeSetting = state.settings.data.find(setting => setting.settingName === 'Period File Chunk Size');
  const periodFileChunkSize = periodFileChunkSizeSetting
    ? parseInt(periodFileChunkSizeSetting.settingValue, 10) * 1024 * 1024
    : 60 * 1024 * 1024;

  const periodFileUploadChunkSizeSetting = state.settings.data.find(setting => setting.settingName === 'Period File Upload Chunk Size');
  const periodFileUploadChunkSize = periodFileUploadChunkSizeSetting
    ? parseInt(periodFileUploadChunkSizeSetting.settingValue, 10) * 1024 * 1024
    : 10 * 1024 * 1024;

  return {
    period: state.periods.period,
    status: periodStatusSelector(state),
    projects: state.projects,
    schemaMapping: state.import.upload.schemaMapping,
    fileUpload: state.app.fileUpload,
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
    periodInUse: periodInUseSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    permissions: state.user.permissions.data,
    periodFileChunkSize,
    periodFileUploadChunkSize,
  };
}

export default compose(withStyles(styles), withTheme(), connect(mapStateToProps))(UploadPage);
