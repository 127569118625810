import React from 'react';
import {
  Paper,
  withStyles,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import StickyTable from 'Components/Shared/Table/StickyTable';
import { matchedSupportingAndMainDocHeaders } from 'Constants/SupportingDocuments/MatchedSuppoortingAndMainDocHeaders';
import { unmatchedSupportingDocsHeaders } from 'Constants/SupportingDocuments/UnMatchedSuppoortingDocsHeaders';
import { formatCurrencyString } from 'Helpers/NumberHelpers';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import PropTypes from 'prop-types';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import CheckboxCheckedIcon from 'Assets/Images/check-box-ic-checked.svg';
import CheckBoxUncheckedIcon from 'Assets/Images/check-box-ic.svg';
import { strings } from 'Constants/SupportingDocuments/Strings';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ArrowButton, {
  constants as arrowButtonConstants,
} from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import { getMatchedSupportingAndMainDocsDataSelector, getUnmatchedSupportingDocsSelector } from 'Store/Areas/Import/ImportSelectors';
import { isMatchedSupportingAndMainDocsProRated } from 'Store/Areas/Import/SupportingDocumentMappingActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';

import { styles } from './MatchedSupportingAndMainDocDetails.styles.';

class MatchedSupportingDocAndMainDocDetails extends React.Component {
  getCell(rowId, cell, headerName) {
    const { classes, currencySymbol } = this.props;
    if (headerName === strings.isProRate || headerName === strings.isBalancingFigure) {
      return (
        <Button
          onClick={() => this.isProRated(rowId, headerName)}
          className={classes.imgButton}
          disableRipple
        >
          <img
            alt={strings.proRateCheckBoxAlternateText}
            src={this.getProRateCheckBox(rowId, headerName)}
          />
        </Button>
      );
    }
    if (headerName === strings.unmatchedValue) {
      return cell;
    }
    if (headerName !== strings.matchingValue) {
      return formatCurrencyString(cell, currencySymbol);
    }
    return cell;
  }

  getProRateCheckBox = (rowId, headerName) => {
    const { matchedSupportingAndMainDocsData } = this.props;
    const matchedData = matchedSupportingAndMainDocsData.filter(x => x.id === rowId);

    if (matchedData[0][headerName]) {
      return CheckboxCheckedIcon;
    }
    return CheckBoxUncheckedIcon;
  };

  getAllSelectedCheckBox = (headerName) => {
    const { matchedSupportingAndMainDocsData } = this.props;

    const isAllProRatedSelected = !matchedSupportingAndMainDocsData.some(matchedData =>
      matchedData.isProRate === false);
    const isAllBalancingFigureSelected = !matchedSupportingAndMainDocsData.some(matchedData =>
      matchedData.isBalancingFigure === false);

    if (headerName === strings.isProRate) {
      if (isAllProRatedSelected) {
        return CheckboxCheckedIcon;
      }
      return CheckBoxUncheckedIcon;
    }
    if (isAllBalancingFigureSelected) {
      return CheckboxCheckedIcon;
    }
    return CheckBoxUncheckedIcon;
  };

  isProRated = (rowId, headerName) => {
    const { dispatch, periodId } = this.props;
    dispatch(isMatchedSupportingAndMainDocsProRated(
      periodId,
      rowId,
      headerName,
      false,
      false,
      false,
    ));
  }

  isAllProRated = (headerName) => {
    const { dispatch, periodId } = this.props;
    const { matchedSupportingAndMainDocsData } = this.props;

    const isAllProRatedSelected = !matchedSupportingAndMainDocsData.some(matchedData =>
      matchedData.isProRate === false);
    const isAllBalancingFigureSelected = !matchedSupportingAndMainDocsData.some(matchedData =>
      matchedData.isBalancingFigure === false);

    let allProRated = false;
    let allBalancingFigure = false;
    if (headerName === strings.isProRate) {
      allProRated = !isAllProRatedSelected;
    } else {
      allBalancingFigure = !isAllBalancingFigureSelected;
    }
    dispatch(isMatchedSupportingAndMainDocsProRated(
      periodId,
      0,
      headerName,
      true,
      allProRated,
      allBalancingFigure,
    ));
  }

  render() {
    const {
      classes,
      onBack,
      onProceed,
      matchedSupportingAndMainDocsData,
      unmatchedSupportingDocs,
    } = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.arrowButton}>
            <ArrowButton
              direction={arrowButtonConstants.direction.back}
              onClickEvent={() => onBack()}
              className={classes.backButton}
            />
          </div>
        </div>
        <div className={classes.header}>{strings.pageTitle}</div>
        <div className={classes.flexContainer}>
          <div className={`${classes.flex} ${classes.description}`}>
            {strings.matchedSupportingAndMainDocDetailsDescription}
          </div>
          {matchedSupportingAndMainDocsData.length > 0 && (
            <Button
              onClick={() => onProceed()}
              className={classes.proceedButton}
              height={buttonConstants.height.big}
            >
              {strings.process}
            </Button>
          )}
        </div>
        <div className={classes.flexContainer}>
          <div className={`${classes.flex} ${classes.description}`}>
            {strings.matchedSupportingAndMainDocDetailsDescription2}
          </div>
        </div>
        <If condition={matchedSupportingAndMainDocsData.length > 0}>
          <StickyTable bodyHeight={200}>
            <TableHead>
              <TableRow>
                <For each="header" of={matchedSupportingAndMainDocHeaders}>
                  <TableCell className={`${classes.cell} ${classes.columnHeader}`} key={header.name}>
                    <div>
                      <If condition={
                        header.name === strings.isProRate ||
                        header.name === strings.isBalancingFigure}
                      >
                        <Button
                          onClick={() =>
                            this.isAllProRated(header.name)
                          }
                          className={classes.imgButton}
                          disableRipple
                        >
                          <img
                            alt={strings.proRateCheckBoxAlternateText}
                            src={this.getAllSelectedCheckBox(header.name)}
                          />
                        </Button>
                      </If>
                      <b>{header.displayName}</b>
                    </div>
                  </TableCell>
                </For>
              </TableRow>
            </TableHead>
            <TableBody>
              <For each="row" of={matchedSupportingAndMainDocsData}>
                <TableRow key={row.id}>
                  <For each="header" of={matchedSupportingAndMainDocHeaders}>
                    <TableCell className={`${classes.cell}`} key={`${row.id}_${header.name}`}>
                      <If condition={header.name in row}>
                        {this.getCell(row.id, row[header.name], header.name)}
                      </If>
                    </TableCell>
                  </For>
                </TableRow>
              </For>
            </TableBody>
          </StickyTable>
        </If>
        <If condition={matchedSupportingAndMainDocsData.length === 0}>
          <div className={`${classes.noMatchedRows} ${classes.marginTop}`}>
            {strings.noMatchedRows}
          </div>
        </If>
        <If condition={unmatchedSupportingDocs.length > 0}>
          <div className={classes.marginTop}>
            <div className={classes.header}>{strings.unmatchedSupportingDocs}</div>
            <StickyTable bodyHeight={200}>
              <TableHead>
                <TableRow>
                  <For each="header" of={unmatchedSupportingDocsHeaders}>
                    <TableCell className={`${classes.cell} ${classes.columnHeader}`} key={header.name}>
                      <div>
                        <b>{header.displayName}</b>
                      </div>
                    </TableCell>
                  </For>
                </TableRow>
              </TableHead>
              <TableBody>
                <For each="row" of={unmatchedSupportingDocs}>
                  <TableRow key={row.id}>
                    <For each="header" of={unmatchedSupportingDocsHeaders}>
                      <TableCell className={`${classes.cell}`} key={`${row.id}_${header.name}`}>
                        <If condition={header.name in row}>
                          {this.getCell(row.id, row[header.name], header.name)}
                        </If>
                      </TableCell>
                    </For>
                  </TableRow>
                </For>
              </TableBody>
            </StickyTable>
          </div>
        </If>
      </Paper>
    );
  }
}

MatchedSupportingDocAndMainDocDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  matchedSupportingAndMainDocsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  unmatchedSupportingDocs: PropTypes.arrayOf(PropTypes.object).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    matchedSupportingAndMainDocsData: getMatchedSupportingAndMainDocsDataSelector(state),
    unmatchedSupportingDocs: getUnmatchedSupportingDocsSelector(state),
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(MatchedSupportingDocAndMainDocDetails);
