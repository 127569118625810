import { CREATING_NEW_GROUP, EDIT_GROUP, ENABLE_DEMO_GROUP, EDIT_LOAD_GROUP_SUCCESS, POST_GROUP, POST_NEW_GROUP_SUCCESS, CANCEL_GROUP_CREATION, DELETE_GROUP } from 'Store/Areas/Setup/Admin/GroupActions';

const initialState = {
  showForm: false,
  loading: false,
  isEdit: false,
  payload: {
    id: -1,
  },
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_NEW_GROUP:
    case CANCEL_GROUP_CREATION:
    case POST_GROUP:
    case POST_NEW_GROUP_SUCCESS:
    case EDIT_GROUP:
    case EDIT_LOAD_GROUP_SUCCESS:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
      });
    case ENABLE_DEMO_GROUP:
    case DELETE_GROUP:
      return Object.assign({}, state, {
        showForm: action.showForm,
        payload: action.payload,
      });
    default:
      return state;
  }
}
