export const strings = {
  errorMessage: 'Something went wrong',
  errorMessageRetry: 'Something went wrong, please try again',
  errorRecommendation: 'Please refresh your browser or return to the homepage',
  unauthorised: 'Unauthorised Access',
  unauthorisedRecommendation: 'Please ask for access from an administrator and try again',
  importFailed: 'Import failed',
  importFailedRecommendation: 'Sorry, the import has failed, please select an option below.',
  externalReviewImportFailed: 'External Review Import failed',
  externalReviewImportFailedRecommendation: 'Sorry, the external review import has failed, please select the option below to retry the import.',
  addNewUserErrorMsg: 'This user is already entity admin or project user.',
  externalReviewEmptyFile: 'The mandatory fields for at least one row must be completed to upload a file. Please review the file and ensure any updates are saved. If a data upload is no longer required please press ‘Cancel External Review’ to unlock the period',
  ruleConflictFailed: 'Rule conflict resolution failed. Please retry',
};
