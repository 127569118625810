import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings } from 'Constants/Error/Strings';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function* handleConflictEvent(payload, action) {
  if (payload.error) {
    if (payload.action === 'ResolveConflict') {
      yield put(displayToastNotification(strings.ruleConflictFailed));
    } else {
      yield this.handleError(action);
    }
  } else {
    yield put(getPeriod(action.selectedPeriodId));
  }
}

export const genericConflictHandler = new SignalrStrategyHandler(
  [userActions.ResolveConflict,
    userActions.AllowConflict,
    userActions.AllowAllConflicts,
    userActions.EditRulePriority],
  function* handler(payload, action) {
    yield handleConflictEvent(payload, action);
  },
);
